import { ReactNode } from 'react'
import { Button } from 'components/marketing/v2/Button'
import { useUserContext } from 'components/UserProvider'

type HeroWithSideContentProps = {
  headline: string
  subheadline: string
  eyeBrow?: ReactNode
  belowCtaContent?: ReactNode
  sideContent: ReactNode
}

export function HeroWithSideContent({
  headline,
  subheadline,
  eyeBrow,
  belowCtaContent,
  sideContent,
}: HeroWithSideContentProps) {
  const { user } = useUserContext()

  return (
    <section className='max-w-7xl px-6 mx-auto relative'>
      <div className='py-10 lg:py-20 lg:grid lg:grid-cols-2 lg:items-center'>
        <div className='flex flex-col gap-12'>
          <div className='space-y-4'>
            {eyeBrow && <div>{eyeBrow}</div>}
            <div className='space-y-6'>
              <h1 className='text-32 text-balance font-bold leading-1.1 text-neutral-110 md:text-36 lg:text-48'>
                {headline}
              </h1>
              <p className='text-20 text-balance font-medium lg:text-24'>
                {subheadline}
              </p>
              <div className='flex gap-2.5 flex-wrap'>
                <Button
                  href='https://go.stellate.co/calendar?utm_source=website&utm_medium=homepage&utm_campaign=RAD'
                  color='red'
                >
                  Schedule a demo
                </Button>
                <Button href={user ? '/app' : '/api/auth/github'} color='gray'>
                  {user ? 'Go to dashboard' : 'Get started for free'}
                </Button>
              </div>
            </div>
          </div>
          {belowCtaContent && <div>{belowCtaContent}</div>}
        </div>
        <div className='mt-12 lg:mt-0'>{sideContent}</div>
      </div>
    </section>
  )
}
